<template>
  <div class="wrap">
    <head-nav></head-nav>
    <div class="con-wrap">
      <b-row class="b-row">
        <b-col sm="12" md="8" lg="8" xl="8">
          <div class="left-wrap">
            <div class="l-con-wrap">
              <!-- <el-carousel class="banner-wrap" trigger="click" :autoplay="autoPlay" @change="banner" ref="carousel">
                <el-carousel-item v-for="(item, index) in model3DInfo.imgArr" :key="index">
                  <div class="pic-wrap">
                    <img :src="item" :alt="model3DInfo.modelName">
                  </div>
                </el-carousel-item>
              </el-carousel>
              <div class="banner-thumbs">
                <ul class="clearfix">
                  <li @click="bannerChange(index)" @mouseenter="stopAutoPlay" @mouseleave="startAutoPlay" v-for="(item, index) in model3DInfo.imgArr" :key="index">
                    <img :src="item" :alt="model3DInfo.modelName">
                  </li>
                </ul>
              </div> -->
              
              <div class="swiper-container banner-wrap gallery-top">
                <div class="swiper-wrapper">
                  <div class="swiper-slide pic-wrap" v-for="(item, index) in model3DInfo.imgArr" :key="index">
                    <img :src="item" :alt="model3DInfo.modelName">
                  </div>
                </div>
              </div>
              <div class="swiper-container banner-thumbs gallery-thumbs">
                <div class="swiper-wrapper">
                  <div class="swiper-slide" v-for="(item, index) in model3DInfo.imgArr" :key="index">
                    <img :src="item" :alt="model3DInfo.modelName">
                  </div>
                </div>
              </div>
              
              
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="4" lg="4" xl="4">
          <div class="right-wrap">
            <div class="r-col-wrap">
              <div class="model-name">{{model3DInfo.modelName}}</div>
              <div class="model-id-wrap">
                <span>ID:{{model3DInfo.modelId}}</span>
                <span>{{$t("model3DDetailCopy")}}</span>
              </div>
              <div class="related-info">
                <!-- <span>
                  <i class="el-icon-user"></i>{{model3DInfo.createUser ? model3DInfo.createUser : '消失~的子弹'}}
                </span> -->
                <span>
                  <i class="el-icon-view"></i>{{model3DInfo.viewTotal}}
                </span>
                <span>
                  <i class="el-icon-download"></i>{{model3DInfo.downloadTotal ? model3DInfo.downloadTotal : 0}}
                </span>
              </div>
              <div class="model-detail-info">
                <p v-if="model3DInfo.updateDate">
                  <span>{{$t("model3DDetailUpdateTime")}}</span>
                  <span>{{model3DInfo.updateDate}}</span>
                </p>
                <p>
                  <span>{{$t("model3DDetailFileSize")}}</span>
                  <span>{{(model3DInfo.fileSize/1024/1024).toFixed(2)}}M</span>
                </p>
                <p v-if="styleType">
                  <span>{{$t("model3DDetailStyle")}}</span>
                  <span>{{styleType}}</span>
                </p>
                <p>
                  <span>{{$t("model3DDetailMapping")}}</span>
                  <span>{{$t("model3DDetailHas")}}</span>
                </p>
                <p>
                  <span>VR/CR：</span>
                  <span>{{model3DInfo.modelType === 1 ? 'VR' : 'CR'}}</span>
                </p>
                <p v-if="model3DInfo.lightInfo">
                  <span>{{$t("model3DDetailLightingDetails")}}</span>
                  <span>{{model3DInfo.lightInfo}}</span>
                </p>
                <p v-if="goodsMarque">
                  <span>{{$t("model3DDetailMarque")}}</span>
                  <span>{{goodsMarque}}</span>
                </p>
                <!-- <p v-if="brandName || styleType">
                  <span>{{brandName || matchBrand.brandName}}</span>
                  <span class="brand-logo-wrap" @click="searchBrand(brandId || matchBrand.styleType)">
                    <img v-if="brandLogo" :src="brandLogo" :alt="brandName">
                    <img v-else :src="matchBrand.brandLogo" :alt="matchBrand.brandName">
                  </span>
                </p> -->
                <!-- <p>
                  <span>MAX{{$t("model3DDetailVersion")}}</span>
                  <span>{{model3DInfo.lightVersion}}</span>
                </p> -->
              </div>
              <div class="btn-wrap">
                <el-button type="warning" plain @click="download3DModel">{{$t("model3DDetailDownloadNow")}}</el-button>
                <el-button type="warning" plain @click="toProductDetail">{{$t("model3DDetailPurchaseGoods")}}</el-button>
              </div>
              <div class="items-wrap">
                <div class="item">
                  <a href="javascript:void(0);" @click="model3DCollection">
                    <i :class="isCollection ? 'el-icon-star-on' : 'el-icon-star-off'"></i>
                    <span>{{isCollection ? $t("model3DDetailIsCollection") : $t("model3DDetailCollection")}}</span>
                  </a>
                </div>
                <div class="item">
                  <a href="javascript:void(0);" @click="model3DShare">
                    <i class="el-icon-share"></i>
                    <span>{{$t("model3DDetailShare")}}</span>
                  </a>
                </div>
                <div class="item">
                  <a href="javascript:void(0);">
                    <i class="el-icon-document"></i>
                    <span>{{$t("model3DDetailErrorCorrection")}}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <footer-nav></footer-nav>
    <div class="gray" v-if="openShareQrcode" @click.stop="closeShareQrcode">
      <div class="qrcode" @click.stop>
        <vue-qr :logoSrc="imageUrl" :logoMargin="2" :text="qrcodeUrl" :size="200"></vue-qr>
      </div>
    </div>
  </div>
</template>

<script>
  import headNav from "@/components/Navbar";
  // import Slides from "@/components/Slides.vue"
  import Swiper from "swiper/swiper-bundle.min";
  import 'swiper/swiper-bundle.min.css';
  import dump from "@/util/dump";
  import footerNav from "@/components/Footer";
  import vueQr from 'vue-qr';
  import Url from "@/api/config";
  export default {
    components: {
      headNav,
      footerNav,
      vueQr
    },
    data() {
      return {
        model3DInfo: [],
        autoPlay: true,
        galleryThumbs: null,
        galleryTop: null,
        token: null,
        isCollection: 0,
        imageUrl: require("@/../public/icon/ehome-logo.png"),
        qrcodeUrl: "",
        openShareQrcode: false,
        styleType: "",
        goodsMarque: "",
        brandName: "",
        brandLogo: "",
        brandId: "",
        brandType: "",
        brands: [
          {
            brandName: "臻选极简",
            styleType: 16,
            brandLogo: require("@/../public/icon/product/details/jjian.png")
          },
          {
            brandName: "臻选轻奢",
            styleType: 15,
            brandLogo: require("@/../public/icon/product/details/qshe.png")
          },
          {
            brandName: "臻选中式",
            styleType: 53,
            brandLogo: require("@/../public/icon/product/details/zshi.png")
          }
        ],
        roleId: ''
      }
    },
    created() {
      this.init();
    },
    mounted() {
      
    },
    computed: {
      matchBrand(){
        let brand = {};
        this.styleType
        this.brands.forEach(item => {
          if(item.brandName.substring(2,4) == this.styleType){
            brand = item;
          }
        })
        
        return brand;
      }
    },
    methods: {
      init() {
        this.qrcodeUrl = window.location.href;
        this.token = localStorage.getItem("token");
        this.roleId = localStorage.getItem("role_id");
        this.$api.model3DDetail({
          modelId: this.$route.query.id,
          token: this.token
        }).then(res => {
          if(res.status === 200){
            this.model3DInfo = res.data.model;
            // 风格
            this.styleType = res.data.styleName;
            // 商品型号
            this.goodsMarque = res.data.goodsMarque;
            // 品牌名称
            this.brandName = res.data.brandName;
            // 品牌logo
            this.brandLogo = res.data.brandImg;
            // 品牌ID
            this.brandId = res.data.brandId;
            this.brandType = res.data.brandType;
            this.isCollection = res.data.collectStatus;
            this.$set(this.model3DInfo, 'imgArr', this.model3DInfo.img.split(","));
            this.$nextTick(() => {
              this.swiper();
            })
          }
        }).catch(err => console.error(err))
      },
      // bannerChange(i){
      //   console.log(i);
      //   this.$refs.carousel.setActiveItem(i);
      // },
      // stopAutoPlay(){
      //   console.log("鼠标移入")
      //   this.autoPlay = false;
      // },
      // startAutoPlay(){
      //   console.log("鼠标离开")
      //   this.autoPlay = true;
      // },
      // banner(){
      //   // console.log(i)
      // },
      swiper() {
        this.galleryThumbs = new Swiper('.gallery-thumbs', {
          spaceBetween: 10,
          slidesPerView: 6,
          freeMode: true,
          watchSlidesVisibility: true,
          watchSlidesProgress: true,
          observer:true,
          observeParents:true
        });
        this.galleryTop = new Swiper('.gallery-top', {
          spaceBetween: 10,
          loop: true,
          pauseOnMouseEnter: true,
          observer:true,
          observeParents:true,
          thumbs: {
            swiper: this.galleryThumbs
          }
        });
      },
      toProductDetail(){
        dump.link({
          type: 3,
          link: "/product/details",
          params: {
            good_id: this.model3DInfo.goodsId
          }
        })
      },
      // 通过URL地址把3D模型转为blob对象
      async url2Blob(fileName, url){
        const content = await new Promise(resolve => {
          var xhr = new XMLHttpRequest();
          xhr.open("get", url, true);
          xhr.responseType = "blob";
          xhr.onload = function(){
            if(this.status == 200){
              resolve(this.response);
            }
          };
          xhr.send();
        })
        this.createDownLoad(fileName, content);
      },
      // 创建文件下载函数
      createDownLoad(fileName, blob){
        var ele = document.createElement('a');// 创建下载链接
        ele.download = fileName;//设置下载的名称
        ele.style.display = 'none';// 隐藏的可下载链接
        // 字符内容转变成blob地址 
        ele.href = URL.createObjectURL(blob);
        // 绑定点击时间
        document.body.appendChild(ele);
        ele.click();
        // 然后移除
        document.body.removeChild(ele);
      },
      
      // 下载三维模型
      download3DModel(){
        // this.$api.model3DDownload({
        //   modelId: this.model3DInfo.modelId,
        //   token: this.token
        // }).then(res => {
        //   if(res.status == 200){ 
        //     this.url2Blob(this.model3DInfo.modelName, res.data);
        //   }
        //   // 下载接口调用不成功时给出提示
        //   if(res.status == 101){
        //     this.$message.error(res.msg);
        //   }
        // }).catch(err => console.error(err))
        
        // 检测用户是否登录
        if (!dump.checkLogin()) {
          return false;
        }
        
        let eb = 18;
        
        // de9de2f006e145a29d52dfadda295353 运营商
        // 38e045588e0e4ce19b80ca40f5325934 高级会员 = 合伙人 = 分销商
        // 如果role_id == 4 || role_id == de9de2f006e145a29d52dfadda295353 直接进入模型下载
        if(this.roleId == 4 || this.roleId == 'de9de2f006e145a29d52dfadda295353' || this.roleId == '38e045588e0e4ce19b80ca40f5325934'){
          this.$message({
            message: '尊敬的高级会员您拥有该模型无限免费下载的权利',
            type: 'success'
          });
          setTimeout(() => {
            this.toModelDownload();
          },1000);
        }else{
          // 查询用户套餐资源
          this.$api.getUsable({
            token: this.token
          }).then(res => {
            if(res.status === 100){
              let identity = "";
              switch(this.roleId){
                case '3' : identity = "注册用户";
                break;
                case '5' : identity = "季会员";
                break;
                case '6' : identity = "年会员";
                break;
              }
              
              let resource = {};
              res.data.forEach(item => {
                if(item.resourceName == '3D模型下载'){
                  resource = item;
                }
              })
              
              // resource.resourceRemain > 0 用户还有套餐使用量
              if(resource.resourceRemain > 0){
                this.$alert(`尊敬的${identity}您还有${resource.resourceRemain}次模型下载使用量`, '温馨提示', {
                  confirmButtonText: '确定',
                  callback: val => {
                    // 用户取消
                    if(val == 'cancel') return;
                    this.toModelDownload();
                  }
                });
              }else{
                // 用户套餐使用量已用完；查看用户是否有e币可以使用购买该作品 
                this.$alert(`尊敬的${identity}您的免费模型下载套餐使用量已用完，是否通过花费${eb}e币购买该模型下载的权限？`, '温馨提示', {
                  confirmButtonText: '确定',
                  callback: val => {
                    // 用户取消
                    if(val == 'cancel') return;
                    
                    this.$api.getUserWallet({
                      token: this.token
                    }).then(res => {
                      console.log(res)
                      if(res.status === 100){
                        if(res.data.balance >= eb){
                          // 使用e币购买
                          // -2:二维方案 -3:拼图 -4:3D模型
                          this.$api.payForDesignerWork({
                            type: -4,
                            token: this.token,
                            id: this.model3DInfo.modelId,
                            num: 1
                          }).then(res => {
                            if(res.status === 100){
                              this.toModelDownload();
                            }
                          }).catch(err => console.error(err));
                        }else{
                          // e币不足以购买该作品跳转到购买VIP套餐页
                          this.$confirm(`您的e币不足，是否前往VIP特权页面购买套餐？`, '温馨提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                          }).then(() => {
                            this.toVipPrivilege();
                          }).catch(() => {});
                        }
                      }
                    }).catch(err => console.error(err));
                  }
                });
              }
            }
          }).catch(err => console.error(err));
        }
      },
      // 模型下载
      toModelDownload(){
        console.log("启动模型下载")
        // 创建下载链接
        let ele = document.createElement('a');
        //设置下载的名称
        // ele.download = fileName;
        // 隐藏的可下载链接
        ele.style.display = 'none';
        // 模型下载链接
        ele.href = `${Url}/mapp/model/getDownload?modelId=${this.model3DInfo.modelId}&token=${this.token}`;
        // 绑定点击时间
        document.body.appendChild(ele);
        ele.click();
        // 然后移除
        document.body.removeChild(ele);
      },
      // 三维模型收藏 
      model3DCollection(){
        this.$api.collection({
          project_id: this.model3DInfo.modelId,
          collection_type: 3,
          token: this.token
        }).then(res => {
          if(res.status == 100){
            this.isCollection = !this.isCollection;
            this.$message({
              message: res.msg,
              type: 'success'
            });
          }
        }).catch(err => console.error(err))
      },
      // 分享三维模型
      model3DShare(){
        this.openShareQrcode = !this.openShareQrcode;
      },
      // 点击遮罩层关闭分享二维码
      closeShareQrcode(){
        this.model3DShare();
      },
      // 搜索品牌
      searchBrand(arg){
        let params = {};
        if(typeof(arg) == "number"){
          params = {
            type: 3,
            // link: "selection_jiaju.html?goods_type=1&style_type=" + arg
            link: "/choosingFurniture",
            params: {
              goods_type: 1,
              style_type: arg
            }
          }
        }else{
          params = {
            type: 3,
            link: "/brandDetails",
            params: {
              brand_type: this.brandType,
              brand_id: this.brandId
            }
          }
        }
        dump.link(params);
      },
      // 跳转到VIP特权套餐页面
      toVipPrivilege(){
        const params = {
          type: 3,
          link: "/vipprivilege"
        }
        dump.link(params);
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/style";
  @import "@/view/model3DDetail/model3DDetail";
</style>
